<template>
  <ElConfigProvider :locale="elementPlusLanguage">
    <NuxtPage />
  </ElConfigProvider>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useFavicon } from '@vueuse/core'
import es from 'element-plus/es/locale/lang/es'
import en from 'element-plus/es/locale/lang/en'

export default defineComponent({
  setup() {
    const route = useRoute()
    const { locale } = useI18n({ useScope: 'global' })
    locale.value = 'en'
    return {
      route,
      locale
    }
  },
  data() {
    return {
      elementPlusLanguage: null,
      newColorScheme: 'light'
    }
  },
  watch: {
    newColorScheme() {
      useFavicon(this.favicon, {
        rel: 'icon'
      })
    }
  },
  computed: {
    favicon() {
      return this.newColorScheme === 'dark'
        ? '/favicon.svg'
        : '/favicon-dark.svg'
    }
  },
  mounted() {
    this.setColorScheme()
    this.setElementPlusLocale()
    this.$eventBus.$on('setLocale', ($event) => {
      this.$i18n.locale = $event
      localStorage.setItem('languageSelected', $event)
      this.setElementPlusLocale(true)
    })
  },
  watch: {
    '$route.fullPath'() {
      setTimeout(() => {
        this.setElementPlusLocale()
      }, 0)
    }
  },
  created() {
    window.addEventListener('resize', this.onResize)
    this.setColorScheme()
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.getContainerSize()
      if (this.containerWidth > 1100) {
        this.drawer = true
      } else {
        this.drawer = false
      }
      this.showAvatarDialog = false
    },
    getContainerSize() {
      this.containerWidth = document.body.clientWidth
    },
    setElementPlusLocale(force = false) {
      const language = force
        ? this.$i18n.locale
        : localStorage.getItem('languageSelected')
        ? localStorage.getItem('languageSelected')
        : this.route.fullPath === '/'
        ? 'en'
        : this.route.fullPath === '/community'
        ? 'es'
        : navigator.language.split('-')[0] === 'es'
        ? 'es'
        : 'en'
      this.$i18n.locale = language
      this.locale = language
      switch (language.toLowerCase()) {
        case 'es':
          this.elementPlusLanguage = es
          break
        case 'en':
          this.elementPlusLanguage = en
          break
        default:
          this.elementPlusLanguage = en
          break
      }
    },
    setColorScheme() {
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        this.newColorScheme = 'dark'
      }
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (event) => {
          this.newColorScheme = event.matches ? 'dark' : 'light'
        })
    }
  }
})
</script>

<style scoped lang="scss">
</style>
